var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "LargeModal",
        {
          attrs: {
            background: "white",
            size: "large",
            display: _vm.display,
            padding:
              _vm.creditQualificationStep === "success"
                ? "p-0"
                : "px-6 pt-6 pb-4",
          },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = true
            },
          },
        },
        [
          _c("div", { staticClass: "mx-auto mt-4" }, [
            _c(
              "div",
              { staticClass: "mt-2 w-full" },
              [
                _c(
                  "transition",
                  {
                    key: _vm.creditQualificationStep,
                    attrs: { name: "slideX", mode: "out-in" },
                  },
                  [
                    _c(_vm.creditQualificationStep, {
                      tag: "component",
                      on: {
                        success: _vm.handleSuccess,
                        close: _vm.handleSuccess,
                        bankAdded: _vm.handleSuccess,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showReassurePrompt },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = false
            },
          },
        },
        [
          _c(
            "h5",
            {
              staticClass:
                "text-lg text-center md:text-2xl primary-text font-bold",
            },
            [_vm._v("Are you sure you want to skip this step?")]
          ),
          _c("p", { staticClass: "mt-8 text-grey text-center" }, [
            _vm._v(
              "Your account is not verified and you are not qualified to apply for shopping credit."
            ),
          ]),
          _c("Button", {
            staticClass: "mt-12",
            attrs: { width: "w-full", text: "No, continue this step" },
            on: {
              click: function ($event) {
                _vm.showReassurePrompt = false
              },
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "text-center mt-4 font-semibold text-sm cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.handleSuccess()
                  _vm.showReassurePrompt = false
                },
              },
            },
            [_vm._v(" Yes, skip this step ")]
          ),
        ],
        1
      ),
      _c(
        "LargeModal",
        {
          attrs: {
            display: _vm.showNoCredit,
            size: "large",
            hideClose: "",
            background: "white",
          },
          on: {
            close: function ($event) {
              _vm.showNoCredit = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "md:w-3/5 mx-auto md:px-4 md:pt-4 z-1 mt-12" },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "69",
                    height: "68",
                    fill: "none",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      x: ".25",
                      width: "68",
                      height: "68",
                      rx: "34",
                      fill: "#FEFBE7",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M38.143 19.7289c-1.7326-2.9927-6.0529-2.9927-7.7855 0l-15.06 26.02c-1.7327 2.9928.427 6.7505 3.8927 6.7505h30.12c3.4657 0 5.6255-3.7577 3.8928-6.7505l-15.06-26.02Zm-3.8928 17.7705c-.8238 0-1.5-.6762-1.5-1.5v-4c0-.8239.6762-1.5 1.5-1.5.8239 0 1.5.6761 1.5 1.5v4c0 .8238-.6761 1.5-1.5 1.5Zm-1.5 8v-3h3v3h-3Z",
                      fill: "#CD9C25",
                      stroke: "#CD9C25",
                    },
                  }),
                ]
              ),
              _c(
                "h3",
                { staticClass: "md:text-xl text-lg text-dark font-bold mt-4" },
                [_vm._v("We cannot extend credit to you at this time")]
              ),
              _c(
                "div",
                { staticClass: "reason-container mt-2" },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between p-4" },
                    [
                      _c("p", { staticClass: "text-dark" }, [
                        _vm._v("Here's why:"),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.userRegistrationTodo, function (todo, index) {
                    return _c("Todos", {
                      key: todo + index,
                      attrs: { todo: todo },
                    })
                  }),
                ],
                2
              ),
              _c("p", { staticClass: "mt-4 text-grey" }, [
                _vm._v(
                  "For now, you can use Zilla to make payments in full at your favourite stores and enjoy 2% instant cashback."
                ),
              ]),
              _c("Button", {
                staticClass: "mt-6",
                attrs: { text: "Continue", width: "w-full" },
                on: {
                  click: function ($event) {
                    _vm.showNoCredit = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }