<template>
  <div>
    <div>
      <hr class="line" />
      <div class="reason-row mt-2 py-2 px-2 md:p-4">
        <div class="reason-icon__container">
          <svg-icon class="cursor-pointer" color="#cd9c25" type="mdi" :path="handleTodoIcon(todo.key)"></svg-icon>
        </div>
        <div>
          <div class="flex items-center justify-between">
            <p class="font-semibold text-dark capitalize">
              {{ todo.key.replace(/_/g, " ") | capitalize }}
            </p>
            <svg-icon
              @click.stop.native="displayDropdown"
              class="cursor-pointer"
              color="#1E1D20"
              type="mdi"
              :path="showItem ? svgPath.minus : svgPath.hospital"
            ></svg-icon>
          </div>
          <AnimateHeight>
            <div v-if="showItem">
              <p class="text-dark text-xs" v-if="todo.key === 'ADDRESS_VERIFICATION'">
                {{ todo.details }}
              </p>
              <div>
                <div v-for="(action, index) in todo.actions" :key="action + index">
                  <div class="flex items-center mt-2">
                    <span v-if="!action.todo && !todo.actions.length === 0">
                      <svg-icon color="#cd9c25" type="mdi" :path="svgPath.dot"></svg-icon>
                    </span>
                    <p class="text-dark font-semibold text-xs">
                      {{ action.reason }}
                    </p>
                  </div>
                  <div v-if="action.todo">
                    <p class="text-dark text-xs mt-1">Consider:</p>
                    <div class="flex items-center">
                      <span>
                        <svg-icon color="#cd9c25" type="mdi" :path="svgPath.dot"></svg-icon>
                      </span>
                      <p class="text-grey text-xs" v-for="(mainTodo, index) in action.todo" :key="mainTodo + index">
                        {{ mainTodo.content }}
                      </p>
                    </div>
                  </div>
                  <div v-if="action.alternativeTodo">
                    <div class="flex items-center">
                      <span>
                        <svg-icon color="#cd9c25" type="mdi" :path="svgPath.dot"></svg-icon>
                      </span>
                      <p class="text-grey text-xs mt-1" v-for="(subTodo, index) in action.alternativeTodo" :key="subTodo + index">
                        {{ subTodo.content }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mdiChevronDown, mdiAlertCircleOutline, mdiHomeAlertOutline, mdiCreditCardOutline, mdiPlus, mdiCircleSmall, mdiMinus } from "@mdi/js";
  import AnimateHeight from "@/UI/AnimateHeight";

  export default {
    components: {
      AnimateHeight,
    },
    props: {
      todo: {
        type: Object,
        default: () => {},
        required: false,
      },
    },
    data: () => ({
      svgPath: {
        arrowDown: mdiChevronDown,
        warningCircle: mdiAlertCircleOutline,
        home: mdiHomeAlertOutline,
        card: mdiCreditCardOutline,
        hospital: mdiPlus,
        dot: mdiCircleSmall,
        minus: mdiMinus,
      },
      showItem: false,
    }),
    methods: {
      displayDropdown() {
        this.showItem = !this.showItem;
      },
      handleTodoIcon(key) {
        let icon;
        switch (key) {
          case "CREDIT_QUALIFICATION":
            icon = this.svgPath?.warningCircle;
            break;
          case "ADDRESS_VERIFICATION":
            icon = this.svgPath?.home;
            break;
          case "IDENTITY_VERIFICATION":
            icon = this.svgPath?.card;
            break;
          default:
            icon = this.svgPath?.warningCircle;
        }
        return icon;
      },
    },
  };
</script>
<style scoped>
  /* .line {
    height: 1rem;
    border: 1px solid #e0dfe3;
    background: #e0dfe3;
  } */
  .reason-container {
    background: #fefbe7;
    border: 0.5px solid #ffdb6b;
    border-radius: 8px;
  }

  .reason-icon__container {
    display: grid;
    place-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    /* background: #cd9c25; */
  }
  .reason-row {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 8px;
    /* padding: 1rem; */
    align-content: center;
  }

  .line {
    border: 0.5px solid #ffdb6b;
    background: #ffdb6b;
    height: 0.5px;
    width: 100%;
  }
</style>
