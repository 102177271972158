var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("hr", { staticClass: "line" }),
      _c("div", { staticClass: "reason-row mt-2 py-2 px-2 md:p-4" }, [
        _c(
          "div",
          { staticClass: "reason-icon__container" },
          [
            _c("svg-icon", {
              staticClass: "cursor-pointer",
              attrs: {
                color: "#cd9c25",
                type: "mdi",
                path: _vm.handleTodoIcon(_vm.todo.key),
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "flex items-center justify-between" },
              [
                _c("p", { staticClass: "font-semibold text-dark capitalize" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("capitalize")(_vm.todo.key.replace(/_/g, " "))
                      ) +
                      " "
                  ),
                ]),
                _c("svg-icon", {
                  staticClass: "cursor-pointer",
                  attrs: {
                    color: "#1E1D20",
                    type: "mdi",
                    path: _vm.showItem
                      ? _vm.svgPath.minus
                      : _vm.svgPath.hospital,
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.displayDropdown.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c("AnimateHeight", [
              _vm.showItem
                ? _c("div", [
                    _vm.todo.key === "ADDRESS_VERIFICATION"
                      ? _c("p", { staticClass: "text-dark text-xs" }, [
                          _vm._v(" " + _vm._s(_vm.todo.details) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      _vm._l(_vm.todo.actions, function (action, index) {
                        return _c("div", { key: action + index }, [
                          _c("div", { staticClass: "flex items-center mt-2" }, [
                            !action.todo && !_vm.todo.actions.length === 0
                              ? _c(
                                  "span",
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        color: "#cd9c25",
                                        type: "mdi",
                                        path: _vm.svgPath.dot,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "p",
                              {
                                staticClass: "text-dark font-semibold text-xs",
                              },
                              [_vm._v(" " + _vm._s(action.reason) + " ")]
                            ),
                          ]),
                          action.todo
                            ? _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-dark text-xs mt-1" },
                                  [_vm._v("Consider:")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            color: "#cd9c25",
                                            type: "mdi",
                                            path: _vm.svgPath.dot,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      action.todo,
                                      function (mainTodo, index) {
                                        return _c(
                                          "p",
                                          {
                                            key: mainTodo + index,
                                            staticClass: "text-grey text-xs",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(mainTodo.content) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                          action.alternativeTodo
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            color: "#cd9c25",
                                            type: "mdi",
                                            path: _vm.svgPath.dot,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      action.alternativeTodo,
                                      function (subTodo, index) {
                                        return _c(
                                          "p",
                                          {
                                            key: subTodo + index,
                                            staticClass:
                                              "text-grey text-xs mt-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(subTodo.content) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }