<template>
  <div>
    <LargeModal
      background="white"
      size="large"
      :display="display"
      :padding="creditQualificationStep === 'success' ? 'p-0' : 'px-6 pt-6 pb-4'"
      @close="showReassurePrompt = true"
    >
      <div class="mx-auto mt-4">
        <div class="mt-2 w-full">
          <transition name="slideX" mode="out-in" :key="creditQualificationStep">
            <component :is="creditQualificationStep" @success="handleSuccess" @close="handleSuccess" @bankAdded="handleSuccess" />
          </transition>
        </div>
      </div>
      <!-- <template v-slot:title>
        <div class="lg:w-9/12 w-full flex items-center justify-around mx-auto">
          <div class="flex items-center">
            <div
              class="md:h-8 md:w-8 h-6 w-6 rounded-full grid place-content-center mr-2"
              :class="[creditQualificationStep === 'Work' ? 'bg-brandPurple' : 'bg-lightGrey-1']"
            >
              <p class="text-xs md:text-sm" :class="[creditQualificationStep === 'Work' ? 'text-white' : '']">1</p>
            </div>
            <p class="text-xs md:text-sm" :class="[creditQualificationStep === 'Work' ? 'text-brandPurple' : 'text-grey']">Work Info</p>
          </div>
          <div class="line"></div>
          <div class="flex items-center">
            <div
              class="md:h-8 md:w-8 h-6 w-6 rounded-full grid place-content-center mr-2"
              :class="[creditQualificationStep === 'Bank' ? 'bg-brandPurple' : 'bg-lightGrey-1']"
            >
              <p class="text-xs md:text-sm" :class="[creditQualificationStep === 'Bank' ? 'text-white' : '']">2</p>
            </div>
            <p class="text-grey text-xs md:text-sm">Connect Bank</p>
          </div>
        </div>
      </template> -->
    </LargeModal>
    <!-- <Success
      @close="closeCreditQualification"
      :display="showSuccess"
      :name="`${userBio.firstname}`"
      @next="handleCheckout"
      title="Congratulations!"
      :description="successDescription"
    >
    </Success> -->
    <!-- to be sure modal -->
    <Modal :display="showReassurePrompt" @close="showReassurePrompt = false">
      <h5 class="text-lg text-center md:text-2xl primary-text font-bold">Are you sure you want to skip this step?</h5>
      <p class="mt-8 text-grey text-center">Your account is not verified and you are not qualified to apply for shopping credit.</p>
      <Button class="mt-12" width="w-full" text="No, continue this step" @click="showReassurePrompt = false" />
      <p
        class="text-center mt-4 font-semibold text-sm cursor-pointer"
        @click="
          handleSuccess();
          showReassurePrompt = false;
        "
      >
        Yes, skip this step
      </p>
    </Modal>
    <!-- if customer does not get credit -->
    <LargeModal :display="showNoCredit" size="large" hideClose background="white" @close="showNoCredit = false">
      <div class="md:w-3/5 mx-auto md:px-4 md:pt-4 z-1 mt-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="69" height="68" fill="none">
          <rect x=".25" width="68" height="68" rx="34" fill="#FEFBE7" />
          <path
            d="M38.143 19.7289c-1.7326-2.9927-6.0529-2.9927-7.7855 0l-15.06 26.02c-1.7327 2.9928.427 6.7505 3.8927 6.7505h30.12c3.4657 0 5.6255-3.7577 3.8928-6.7505l-15.06-26.02Zm-3.8928 17.7705c-.8238 0-1.5-.6762-1.5-1.5v-4c0-.8239.6762-1.5 1.5-1.5.8239 0 1.5.6761 1.5 1.5v4c0 .8238-.6761 1.5-1.5 1.5Zm-1.5 8v-3h3v3h-3Z"
            fill="#CD9C25"
            stroke="#CD9C25"
          />
        </svg>
        <h3 class="md:text-xl text-lg text-dark font-bold mt-4">We cannot extend credit to you at this time</h3>
        <div class="reason-container mt-2">
          <div class="flex items-center justify-between p-4">
            <p class="text-dark">Here's why:</p>
          </div>
          <Todos v-for="(todo, index) in userRegistrationTodo" :key="todo + index" :todo="todo" />
        </div>
        <p class="mt-4 text-grey">For now, you can use Zilla to make payments in full at your favourite stores and enjoy 2% instant cashback.</p>
        <Button text="Continue" width="w-full" class="mt-6" @click="showNoCredit = false" />
      </div>
    </LargeModal>
  </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import { LargeModal, Modal } from "@/UI/Modals";
  import { Button } from "@/UI/Button";
  import Todos from "./Todos.vue";
  export default {
    props: {
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    data: () => ({
      showSuccess: false,
      showReassurePrompt: false,
      showNoCredit: false,
      // poolTrials: 1,
      successDescription: "",
      showCreditError: false,
      todoDisplays: {},
    }),
    components: {
      Work: () => import("./Work"),
      Bank: () => import("./Bank"),
      LargeModal,
      Modal,
      Button,
      Success: () => import("../Success/CreditSuccess.vue"),
      Todos,
    },
    computed: {
      ...mapState({
        userBio: (state) => state?.auth?.user,
        creditQualificationStep: (state) => state.dashboard.creditQualificationSteps.activeTab,
        creditQualification: (state) => state?.userInfo?.creditQualification,
        employmentStatus: (state) => state.userInfo?.userEmployementInfo?.verificationStatus,
        userRegistrationTodo: (state) => state?.userInfo?.userRegistrationTodo,
      }),
    },
    methods: {
      ...mapActions("dashboard", ["showCreditQualificationStep", "getDashboardData", "getUserWallet", "handleShowReminder"]),
      ...mapActions("userInfo", ["employmentInfo", "getRegistrationTodo"]),
      ...mapActions("loading", ["setLoading"]),
      handleSuccess() {
        this.setLoading({
          display: true,
          description: 'Please wait...'
        });
        this.showCreditQualificationStep(false);
        // this.getRegistrationTodo()
        //   .then(() => {
            // if (this.userRegistrationTodo.length === 0) {
              this.getDashboardData()
                .then(() => {
                  this.setLoading(false);
                  this.getUserWallet();
                  this.handleShowReminder(true);
                })
                .catch(() => {
                  this.setLoading(false);
                });
            // } else {
            //   this.setLoading(false);
            //   this.showNoCredit = true;
            // }
          // })
          // .catch(() => {
          //   this.setLoading(false);
          // });
      },
      closeCreditQualification() {
        this.showSuccess = false;
        this.$emit("close");
      },
      handleCheckout() {
        this.showSuccess = false;
        this.$emit("next");
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .line {
    height: 1rem;
    border: 1px solid #e0dfe3;
    background: #e0dfe3;
  }
  .reason-container {
    background: #fefbe7;
    border: 0.5px solid #ffdb6b;
    border-radius: 8px;
  }

  .reason-icon__container {
    display: grid;
    place-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    /* background: #cd9c25; */
  }
</style>
